import React, { PureComponent } from "react";
import { Map, List } from "immutable";
import PlayingFieldSVG from "./PlayingFieldSVG";
import PlayerSVG from "./PlayerSVG";
import PassIndicatorSVG from "./PassIndicatorSVG";
import { streakerPropability } from "../../App";

class PassingMiniGame extends PureComponent {
  constructor(props) {
    super(props);
    this.colors = List(["#E70000", "#000000", "#1B00E7", "#A600E7", "#FCF40B"]);
    const playerInPossessionIndex = 0;
    let initialState = Map({
      players: List([
        Map({
          team: 1,
          number: 1,
          x: 30,
          y: 97
        }),
        Map({
          team: 1,
          number: 2,
          x: 10,
          y: 72
        }),
        Map({
          team: 1,
          number: 3,
          x: 30,
          y: 58
        }),
        Map({
          team: 1,
          number: 4,
          x: 50,
          y: 72
        }),
        Map({
          team: 1,
          number: 5,
          x: 12,
          y: 32
        }),
        Map({
          team: 1,
          number: 6,
          x: 22,
          y: 23
        }),
        Map({
          team: 1,
          number: 7,
          x: 38,
          y: 23
        }),
        Map({
          team: 1,
          number: 8,
          x: 48,
          y: 32
        }),
        Map({
          team: 2,
          number: 1,
          x: 30,
          y: 5
        }),
        Map({
          team: 2,
          number: 2,
          x: 14,
          y: 16
        }),
        Map({
          team: 2,
          number: 3,
          x: 47,
          y: 16
        }),
        Map({
          team: 2,
          number: 4,
          x: 6,
          y: 51
        }),
        Map({
          team: 2,
          number: 5,
          x: 30,
          y: 38
        }),
        Map({
          team: 2,
          number: 6,
          x: 54,
          y: 51
        }),
        Map({
          team: 2,
          number: 7,
          x: 20,
          y: 70
        }),
        Map({
          team: 2,
          number: 8,
          x: 40,
          y: 70
        })
      ]),
      colors: this.colors,
      currentRound: 0,
      playerInPossessionIndex: playerInPossessionIndex
    });
    // rounds, players, receivers
    this.allowedReceiversPerRoundTableJS = [
      [
        // First round
        // [1, 2, 3]
        [1, 2, 3, 5, 6]
      ],
      [
        // Second round
        [],
        [2, 4, 5, 7],
        [4, 5, 6, 7],
        [2, 4, 6, 7],
        [],
        [1, 2, 4, 6, 7],
        [2, 3, 4, 5, 7],
        []
      ],
      [
        // Third round
        [],
        [4, 5, 7],
        [4, 5, 6, 7],
        [4, 6, 7],
        [5, 6, 7],
        [4, 6, 7],
        [4, 5, 7],
        [4, 5, 6]
      ]
    ];
    let passes = this.generatePasses(0, playerInPossessionIndex);
    initialState = initialState.set("passes", passes);
    this.state = { data: initialState };

    this.handlePassSelection = this.handlePassSelection.bind(this);
  }

  generatePasses(roundIndex, playerInPossessionIndex) {
    let passes = List();
    let round = this.allowedReceiversPerRoundTableJS[roundIndex];
    let playerInPossession = round[playerInPossessionIndex];
    playerInPossession.forEach(receiver => {
      let colorIndex = Math.floor(Math.random() * Math.floor(this.colors.size));
      passes = passes.push(
        Map({
          receiverIndex: receiver,
          colorIndex: colorIndex
        })
      );
    });
    return passes;
  }

  render() {
    const playerFill = "#FFFFFF";
    const playerStroke = "#000000";
    const opponentFill = "#0000FF";
    const opponentStroke = "#FFFFFF";

    let players = this.state.data.get("players");
    let playerInPossessionIndex = this.state.data.get(
      "playerInPossessionIndex"
    );
    let playerInPossession = players.get(playerInPossessionIndex);
    return (
      <div className="PassingMiniGame">
        <p className="game-name light">Afleveringer</p>
        <div className="container">
          <div className="grass">
            <svg className="playingField" viewBox="-5 -5 70 110">
              <PlayingFieldSVG />
              {this.state.data.get("passes").map((pass, i) => (
                <PassIndicatorSVG
                  pass={pass}
                  playerInPossession={playerInPossession}
                  players={players}
                  colors={this.state.data.get("colors")}
                  passIndex={i}
                  key={i}
                  callback={this.handlePassSelection}
                />
              ))}
              {this.state.data.get("players").map((player, i) => (
                <PlayerSVG
                  number={player.get("number")}
                  x={player.get("x") - 4}
                  y={player.get("y") - 8}
                  fill={player.get("team") === 1 ? playerFill : opponentFill}
                  stroke={
                    player.get("team") === 1 ? playerStroke : opponentStroke
                  }
                  key={i}
                />
              ))}
            </svg>
          </div>
        </div>
      </div>
    );
  }

  handlePassSelection(passIndex) {
    let passes = this.state.data.get("passes");
    let pass = passes.get(passIndex);
    let receiverIndex = pass.get("receiverIndex");
    let playerInPossessionIndex = this.state.data.get(
      "playerInPossessionIndex"
    );
    let playerInPossession = this.state.data
      .get("players")
      .get(playerInPossessionIndex);
    let playerInPossessionNumberIsOdd =
      playerInPossession.get("number") % 2 === 1;
    let numRed = passes.filter(i => i.get("colorIndex") === 0).size;
    let numBlack = passes.filter(i => i.get("colorIndex") === 1).size;
    let numBlue = passes.filter(i => i.get("colorIndex") === 2).size;
    // let numPurple = passes.filter(i => i.get("colorIndex") === 3).size;
    let numYellow = passes.filter(i => i.get("colorIndex") === 4).size;
    // let lastPassIsRed = passes.get(passes.size - 1).get("colorIndex") === 0;
    let lastPassIsBlack = passes.get(passes.size - 1).get("colorIndex") === 1;
    // let lastPassIsBlue = passes.get(passes.size - 1).get("colorIndex") === 2;
    let lastPassIsPurple = passes.get(passes.size - 1).get("colorIndex") === 3;
    let lastPassIsYellow = passes.get(passes.size - 1).get("colorIndex") === 4;
    let lastRed = passes.filter(i => i.get("colorIndex") === 0).last();
    // let lastBlack = passes.filter(i => i.get("colorIndex") === 1).last();
    let lastBlue = passes.filter(i => i.get("colorIndex") === 2).last();
    // let lastPurple = passes.filter(i => i.get("colorIndex") === 3).last();
    // let lastYellow = passes.filter(i => i.get("colorIndex") === 4).last();

    let success = false;
    if (passes.size === 3) {
      if (numRed === 0) {
        success = passIndex === 1;
      } else if (lastPassIsPurple) {
        success = passIndex === 2;
      } else if (numBlue > 1) {
        success = receiverIndex === lastBlue.get("receiverIndex");
      } else {
        success = passIndex === 2;
      }
    } else if (passes.size === 4) {
      if (numRed > 1 && playerInPossessionNumberIsOdd) {
        success = receiverIndex === lastRed.get("receiverIndex");
      } else if (lastPassIsYellow && numRed === 0) {
        success = passIndex === 0;
      } else if (numBlue === 1) {
        success = passIndex === 0;
      } else if (numYellow > 1) {
        success = passIndex === 3;
      } else {
        success = passIndex === 1;
      }
    } else if (passes.size === 5) {
      if (lastPassIsBlack && playerInPossessionNumberIsOdd) {
        success = passIndex === 3;
      } else if (numRed === 1 && numYellow > 1) {
        success = passIndex === 0;
      } else if (numBlack === 0) {
        success = passIndex === 1;
      } else {
        success = passIndex === 0;
      }
    }

    if (success) {
      let currentRound = this.state.data.get("currentRound") + 1;
      if (currentRound > 2) {
        this.props.callback(true);
        return;
      }
      let newState = this.state.data
        .set("currentRound", currentRound)
        .set("playerInPossessionIndex", receiverIndex)
        .set("passes", this.generatePasses(currentRound, receiverIndex));
      this.setState({ data: newState }, () => {
        if (Math.random() < streakerPropability) {
          this.props.streakerTrigger();
        }
      });
    } else {
      this.props.callback(false);
    }
  }
}

export default PassingMiniGame;
