import React, { Component } from "react";
import yellowCardIcon from "../../img/YellowCard.svg";
import redCardIcon from "../../img/RedCard.svg";
import timerSound from "../../sfx/beep2.mp3";

class Header extends Component {
  render() {
    let minutesTeam0 = Math.floor(this.props.timeLeftTeam0 / 60);
    let secondsTeam0 = this.props.timeLeftTeam0 - minutesTeam0 * 60;
    let minutesTeam1 = Math.floor(this.props.timeLeftTeam1 / 60);
    let secondsTeam1 = this.props.timeLeftTeam1 - minutesTeam1 * 60;
    if (
      (this.props.currentTeam === 0 && this.props.timeLeftTeam0 <= 10) ||
      (this.props.currentTeam === 1 && this.props.timeLeftTeam1 <= 10)
    ) {
      let audio = new Audio(timerSound);
      audio.play();
    }
    return (
      <div className="Header">
        <span
          className={`team-time${this.props.currentTeam === 0 ? " current" : ""}
          ${this.props.timeLeftTeam0 <= 10 ? " warning" : ""}`}
        >
          {("00" + minutesTeam0).slice(-2)}:{("00" + secondsTeam0).slice(-2)}
        </span>
        <span
          className={`team-name${
            this.props.currentTeam === 0 ? " current" : ""
          }`}
        >
          Hold 1
        </span>
        <span className="warnings">
          {(() => {
            switch (this.props.warningsTeam0) {
              case 1:
                return <img src={yellowCardIcon} alt="Gult kort!" />;
              case 2:
                return <img src={redCardIcon} alt="Rødt kort!" />;
              default:
                return null;
            }
          })()}
        </span>
        <span
          className={`warnings${
            this.props.currentTeam === 0
              ? ""
              : this.props.currentTeam === 1
                ? " yellow"
                : " red"
          }`}
        />
        <span className="score">
          {this.props.scoreTeam0} : {this.props.scoreTeam1}
        </span>
        <span
          className={`team-name${
            this.props.currentTeam === 1 ? " current" : ""
          }`}
        >
          <span className="warnings">
            {(() => {
              switch (this.props.warningsTeam1) {
                case 1:
                  return <img src={yellowCardIcon} alt="Gult kort!" />;
                case 2:
                  return <img src={redCardIcon} alt="Rødt kort!" />;
                default:
                  return null;
              }
            })()}
          </span>
          Hold 2
        </span>
        <span
          className={`team-time${
            this.props.currentTeam === 1 ? " current" : ""
          }${this.props.timeLeftTeam1 <= 10 ? " warning" : ""}`}
        >
          {("00" + minutesTeam1).slice(-2)}:{("00" + secondsTeam1).slice(-2)}
        </span>
      </div>
    );
  }
}

export default Header;
