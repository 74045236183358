import "bootstrap/scss/bootstrap.scss";
import React, { Component } from "react";
import { Map } from "immutable";
import PassingMiniGame from "./components/PassingMiniGame/PassingMiniGame";
import DribblingMiniGame from "./components/DribblingMiniGame/DribblingMiniGame";
import EspionageMiniGame from "./components/EspionageMiniGame/EspionageMiniGame";
import Header from "./components/Header/Header";
import { GameState, Notification, GameType } from "./enums";
import fcwLogo from "./img/FCW_logo.svg";
import yellowCardIcon from "./img/YellowCard.svg";
import redCardIcon from "./img/RedCard.svg";
import goalStarIcon from "./img/GoalStar.svg";
import trophy1Icon from "./img/Trophy1.svg";
import trophy2Icon from "./img/Trophy2.svg";
import trophyTieIcon from "./img/TrophyTie.svg";
import streakerIcon from "./img/Streaker.svg";
import whistleSound from "./sfx/whistle.wav";
import goalSound from "./sfx/yeah6.wav";
import tieSound from "./sfx/pathetic_2.wav";
import winSound from "./sfx/nice3.wav";
import timeUpSound from "./sfx/aww_maaan1.wav";

const availableGameTypes = [
  GameType.PASSING,
  GameType.DRIBBLING,
  GameType.ESPIONAGE
];

const initialState = Map({
  minutesPerTeam: 3,
  scoreTeam0: 0,
  scoreTeam1: 0,
  warningsTeam0: 0,
  warningsTeam1: 0,
  currentTeam: 0,
  currentGameType:
    availableGameTypes[Math.floor(Math.random() * availableGameTypes.length)],
  notification: null,
  gameState: GameState.STARTING
});

export const streakerPropability = 1.0 / 32.0;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { data: initialState };

    this.handleTimePerTeamChange = this.handleTimePerTeamChange.bind(this);
    this.startGame = this.startGame.bind(this);
    this.continueGame = this.continueGame.bind(this);
    this.endGame = this.endGame.bind(this);
    this.timer = this.timer.bind(this);
    this.dismissNotification = this.dismissNotification.bind(this);
    this.changeTeam = this.changeTeam.bind(this);
    this.handleMiniGameCallback = this.handleMiniGameCallback.bind(this);
    this.handleStreakerTrigger = this.handleStreakerTrigger.bind(this);
  }

  render() {
    let gameState = this.state.data.get("gameState");
    let currentGameType = this.state.data.get("currentGameType");
    let currentTeam = this.state.data.get("currentTeam");
    let notification = this.state.data.get("notification");
    let scoreTeam0 = this.state.data.get("scoreTeam0");
    let scoreTeam1 = this.state.data.get("scoreTeam1");
    let timeLeftTeam0 = this.state.data.get("timeLeftTeam0");
    let timeLeftTeam1 = this.state.data.get("timeLeftTeam1");
    let warningsTeam0 = this.state.data.get("warningsTeam0");
    let warningsTeam1 = this.state.data.get("warningsTeam1");
    let minutesPerTeam = this.state.data.get("minutesPerTeam");
    let invalidTimePerTeam = this.state.data.get("invalidTimePerTeam");

    return (
      <div className="App">
        {gameState === GameState.STARTING ? (
          <div className="main-menu-content">
            <img src={fcwLogo} alt="FCW Logo" />
            <h1 className="title">Trænerspillet</h1>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={this.startGame}
            >
              Start kampen
            </button>
            <div className="time-per-team-input-group">
              <label htmlFor="time-per-team">Spilletid pr. hold:</label>
              <input
                id="time-per-team"
                type="number"
                value={minutesPerTeam}
                onChange={this.handleTimePerTeamChange}
                className={invalidTimePerTeam ? "invalid" : null}
              />
              <label htmlFor="time-per-team">minutter.</label>
            </div>
            <div className="manual">
              <a href="manual.pdf" target="blank">Manual</a>
            </div>
          </div>
        ) : (
          <Header
            currentTeam={currentTeam}
            scoreTeam0={scoreTeam0}
            scoreTeam1={scoreTeam1}
            timeLeftTeam0={timeLeftTeam0}
            timeLeftTeam1={timeLeftTeam1}
            warningsTeam0={warningsTeam0}
            warningsTeam1={warningsTeam1}
          />
        )}

        {gameState === GameState.CHANGING && (
          <div className="changing-content">
            <h1 className="title">
              Hold {currentTeam === 0 ? "1" : "2"} har bolden
            </h1>
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={this.continueGame}
            >
              Sæt igang!
            </button>
          </div>
        )}

        {gameState === GameState.PLAYING && (
          <div>
            {(() => {
              switch (currentGameType) {
                case GameType.PASSING:
                  return (
                    <PassingMiniGame
                      callback={this.handleMiniGameCallback}
                      streakerTrigger={this.handleStreakerTrigger}
                    />
                  );
                case GameType.DRIBBLING:
                  return (
                    <DribblingMiniGame
                      callback={this.handleMiniGameCallback}
                      streakerTrigger={this.handleStreakerTrigger}
                    />
                  );
                case GameType.ESPIONAGE:
                  return (
                    <EspionageMiniGame
                      callback={this.handleMiniGameCallback}
                      streakerTrigger={this.handleStreakerTrigger}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        )}

        {notification != null && (
          <div className="notification-panel">
            <div className="card mx-auto" onClick={this.dismissNotification}>
              <div className="card-body">
                {(() => {
                  switch (notification) {
                    case Notification.SCORED:
                      return <img src={goalStarIcon} alt="MÅL!" />;
                    case Notification.WARNING:
                      return <img src={yellowCardIcon} alt="Gult kort!" />;
                    case Notification.DISMISSED:
                      return <img src={redCardIcon} alt="Rødt kort!" />;
                    case Notification.TIE:
                      return <img src={trophyTieIcon} alt="Uafgjort" />;
                    case Notification.WON:
                      if (currentTeam === 0) {
                        return <img src={trophy1Icon} alt="Sejr til hold 1" />;
                      } else {
                        return <img src={trophy2Icon} alt="Sejr til hold 2" />;
                      }
                    case Notification.STREAKER:
                      return <img src={streakerIcon} alt="Baneløber" />;
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  handleTimePerTeamChange(e) {
    var value = Number(e.target.value);
    this.setState({
      data: this.state.data.set("minutesPerTeam", value)
    });
  }

  startGame() {
    let minutesPerTeam = this.state.data.get("minutesPerTeam");

    if (!Number.isInteger(minutesPerTeam)) {
      this.setState({
        data: this.state.data.set("invalidTimePerTeam", true)
      });
      return;
    }
    let seconds = minutesPerTeam * 60;
    this.setState({
      data: this.state.data
        .set("gameState", GameState.CHANGING)
        .set("timeLeftTeam0", seconds)
        .set("timeLeftTeam1", seconds)
    });
  }

  timer() {
    let timeLeftAtIntervalStart = this.state.data.get(
      "timeLeftAtIntervalStart"
    );
    let intervalStartTime = this.state.data.get("intervalStartTime");
    let now = new Date().getTime();
    let timeDelta = now - intervalStartTime;
    let timeDeltaSeconds = Math.floor(timeDelta / 1000);
    let actualTimeLeft = timeLeftAtIntervalStart - timeDeltaSeconds;

    let currentTeam = this.state.data.get("currentTeam");
    let teamTimeKey = currentTeam === 0 ? "timeLeftTeam0" : "timeLeftTeam1";
    let otherTimeKey = currentTeam === 0 ? "timeLeftTeam1" : "timeLeftTeam0";
    let timeLeftDisplayed = this.state.data.get(teamTimeKey);
    let otherTimeLeft = this.state.data.get(otherTimeKey);

    let stateCallback = null;
    if (actualTimeLeft <= 0) {
      clearInterval(this.state.data.get("intervalId"));
      stateCallback = this.changeTeam;
      actualTimeLeft = 0;
      if (otherTimeLeft > 0) {
        let audio = new Audio(timeUpSound);
        audio.play();
      }
    }

    if (timeLeftDisplayed !== actualTimeLeft) {
      this.setState(
        {
          data: this.state.data.set(teamTimeKey, actualTimeLeft)
        },
        stateCallback
      );
    }
  }

  continueGame() {
    let intervalId = setInterval(this.timer, 1000);
    let intervalStartTime = new Date().getTime();
    let currentTeam = this.state.data.get("currentTeam");
    let timeLeft =
      currentTeam === 0
        ? this.state.data.get("timeLeftTeam0")
        : this.state.data.get("timeLeftTeam1");

    this.setState({
      data: this.state.data
        .set("gameState", GameState.PLAYING)
        .set("intervalId", intervalId)
        .set("intervalStartTime", intervalStartTime)
        .set("timeLeftAtIntervalStart", timeLeft)
    });
  }

  endGame() {
    clearInterval(this.state.data.get("intervalId"));
    let scoreTeam0 = this.state.data.get("scoreTeam0");
    let scoreTeam1 = this.state.data.get("scoreTeam1");
    if (scoreTeam0 === scoreTeam1) {
      let audio = new Audio(tieSound);
      audio.play();
      this.setState({
        data: this.state.data
          .set("notification", Notification.TIE)
          .set("notificationTime", new Date().valueOf())
      });
      return;
    }
    let winningTeam;
    if (scoreTeam0 < scoreTeam1) {
      winningTeam = 1;
    } else if (scoreTeam0 > scoreTeam1) {
      winningTeam = 0;
    } else {
      winningTeam = -1;
    }
    let audio = new Audio(winSound);
    audio.play();
    this.setState({
      data: this.state.data
        .set("notification", Notification.WON)
        .set("currentTeam", winningTeam)
        .set("notificationTime", new Date().valueOf())
    });
  }

  dismissNotification() {
    let notificationTime = this.state.data.get("notificationTime");
    if (notificationTime + 1000 < new Date()) {
      // 1 second grace period

      let notification = this.state.data.get("notification");

      if (
        notification === Notification.WON ||
        notification === Notification.TIE
      ) {
        this.setState({
          data: initialState
        });
      } else if (notification === Notification.STREAKER) {
        this.setState({
          data: this.state.data.set("notification", null)
        });
      } else {
        this.changeTeam();
      }
    }
  }

  handleStreakerTrigger() {
    let audio = new Audio(whistleSound);
    audio.play();
    this.setState({
      data: this.state.data
        .set("notification", Notification.STREAKER)
        .set("notificationTime", new Date().valueOf())
    });
  }

  changeTeam() {
    let currentTeam = this.state.data.get("currentTeam");
    let currentTeamTimeKey =
      currentTeam === 0 ? "timeLeftTeam0" : "timeLeftTeam1";
    let currentTeamTimeLeft = this.state.data.get(currentTeamTimeKey);
    let currentTeamWarningsKey =
      currentTeam === 0 ? "warningsTeam0" : "warningsTeam1";
    let currentTeamWarnings = this.state.data.get(currentTeamWarningsKey);

    let otherTeam = currentTeam === 0 ? 1 : 0;
    let otherTeamTimeKey = otherTeam === 0 ? "timeLeftTeam0" : "timeLeftTeam1";
    let otherTeamTimeLeft = this.state.data.get(otherTeamTimeKey);
    let otherTeamWarningsKey =
      otherTeam === 0 ? "warningsTeam0" : "warningsTeam1";
    let otherTeamWarnings = this.state.data.get(otherTeamWarningsKey);

    let shouldChangeGame = true;
    if (otherTeamTimeLeft > 0 && otherTeamWarnings < 2) {
      currentTeam = otherTeam;
      currentTeamTimeLeft = otherTeamTimeLeft;
      currentTeamWarnings = otherTeamWarnings;
      if (currentTeam === 1) {
        // Team 1 should play the same game as team 0 just did.
        shouldChangeGame = false;
      }
    }

    if (currentTeamTimeLeft <= 0 || currentTeamWarnings > 1) {
      this.endGame();
    } else {
      let currentGameType = this.state.data.get("currentGameType");
      let nextGameType = currentGameType;
      if (shouldChangeGame && availableGameTypes.length > 1) {
        do {
          nextGameType =
            availableGameTypes[
              Math.floor(Math.random() * availableGameTypes.length)
            ];
        } while (nextGameType === currentGameType);
      }
      this.setState({
        data: this.state.data
          .set("gameState", GameState.CHANGING)
          .set("currentGameType", nextGameType)
          .set("notification", null)
          .set("currentTeam", currentTeam)
      });
    }
  }

  handleMiniGameCallback(success) {
    clearInterval(this.state.data.get("intervalId"));
    let currentTeam = this.state.data.get("currentTeam");

    var newState = this.state.data.set(
      "notificationTime",
      new Date().valueOf()
    );

    if (success) {
      let scoreKey = currentTeam === 0 ? "scoreTeam0" : "scoreTeam1";
      let score = this.state.data.get(scoreKey);
      var audio = new Audio(goalSound);
      audio.play();
      newState = newState
        .set("notification", Notification.SCORED)
        .set(scoreKey, score + 1);
    } else {
      let warningsKey = currentTeam === 0 ? "warningsTeam0" : "warningsTeam1";
      let warnings = this.state.data.get(warningsKey) + 1;
      let notification =
        warnings === 1 ? Notification.WARNING : Notification.DISMISSED;
      var audio = new Audio(whistleSound);
      audio.play();

      newState = newState
        .set("notification", notification)
        .set(warningsKey, warnings);

      if (warnings >= 2) {
        let timeLeftKey = currentTeam === 0 ? "timeLeftTeam0" : "timeLeftTeam1";
        newState = newState.set(timeLeftKey, 0);
      }
    }
    this.setState({
      data: newState
    });
  }
}

export default App;
