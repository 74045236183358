export const GameState = Object.freeze({
  STARTING: Symbol("starting"),
  CHANGING: Symbol("changing"),
  PLAYING: Symbol("playing")
});

export const Notification = Object.freeze({
  SCORED: Symbol("scored"),
  WARNING: Symbol("warning"),
  DISMISSED: Symbol("game over"),
  WON: Symbol("won"),
  TIE: Symbol("tie"),
  STREAKER: Symbol("streaker")
});

export const GameType = Object.freeze({
  PASSING: Symbol("passing"),
  DRIBBLING: Symbol("dribbling"),
  ESPIONAGE: Symbol("espionage")
});
