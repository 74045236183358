import React, { PureComponent } from "react";

class PlayingFieldSVG extends PureComponent {
  render() {
    let strokeWidth = 0.4;
    let lineColor = "#CCFFCC";
    let lineOpacity = 1;
    return (
      <g>
        {/* Field bounds */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M0 0 H 60 V 100 H 0 L 0 0"
        />
        {/* Center line */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M0 50 H 60"
        />
        {/* Center circle */}
        <circle
          cx="30"
          cy="50"
          r="9"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />
        {/* Center spot */}
        <circle
          cx="30"
          cy="50"
          r={strokeWidth}
          fill={lineColor}
          fillOpacity={lineOpacity}
        />
        {/* Top penalty field */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M14 0 V 14 h 32 V 0"
        />
        {/* Top penalty arc */}
        <path
          d="M24 14 A 8 8 0 0 0 36 14"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />{" "}
        {/* Top penalty spot */}
        <circle
          cx="30"
          cy="9"
          r={strokeWidth}
          fill={lineColor}
          fillOpacity={lineOpacity}
        />
        {/* Top goal field */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M22 0 V 5 h 16 V 0"
        />
        {/* Top goal */}
        <path
          strokeWidth={strokeWidth / 4}
          stroke="#F6F6F6"
          fill="#DDDDDD"
          fillOpacity="0.4"
          d="M26 0 V -3 h 8 V 0"
        />
        {/* Bottom penalty field */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M14 100 V 86 h 32 V 100"
        />
        {/* Bottom penalty arc */}
        <path
          d="M24 86 A 8 8 0 0 1 36 86"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />{" "}
        {/* Bottom penalty spot */}
        <circle
          cx="30"
          cy="91"
          r={strokeWidth}
          fill={lineColor}
          fillOpacity={lineOpacity}
        />
        {/* Bottom goal field */}
        <path
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="Transparent"
          d="M22 100 V 95 h 16 V 100"
        />
        {/* Bottom goal */}
        <path
          strokeWidth={strokeWidth / 4}
          stroke="#FFFFFF"
          fill="#EEEEEE"
          fillOpacity="0.4"
          d="M26 100 V 103 h 8 V 100"
        />
        {/* Top left corner arc */}
        <path
          d="M0 1 A 1 1 0 0 0 1 0"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />{" "}
        {/* Top right corner arc */}
        <path
          d="M60 1 A 1 1 0 0 1 59 0"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />{" "}
        {/* Bottom left corner arc */}
        <path
          d="M0 99 A 1 1 0 0 1 1 100"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />{" "}
        {/* Bottom right corner arc */}
        <path
          d="M59 100 A 1 1 0 0 1 60 99"
          strokeWidth={strokeWidth}
          stroke={lineColor}
          strokeOpacity={lineOpacity}
          fill="transparent"
        />
      </g>
    );
  }
}

export default PlayingFieldSVG;
