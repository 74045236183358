import React, { PureComponent } from "react";

class PlayerSVG extends PureComponent {
  render() {
    let strokeWidth = 0.1;
    return (
      <svg x={this.props.x} y={this.props.y} width="8" height="12" viewBox="-2.5 0 5 4" style={{userSelect: "none"}}>
        <path
          strokeWidth={strokeWidth}
          stroke={this.props.stroke}
          fill={this.props.fill}
          d="M-1 4.5 V 2 L -1.5 2.5 L -2 1.9 L -1 0.9 H -0.5 L 0 1.1 L 0.5 0.9 H 1 L 2 1.9 L 1.5 2.5 L 1 2 V 4.5 H -1"
        />
        <text x="-0.7" y="3.5" style={{font: "bold 2.6px sans-serif", fill: this.props.stroke}}>{this.props.number}</text>
      </svg>
    );
  }
}

export default PlayerSVG;
