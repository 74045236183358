import React, { PureComponent } from "react";
import { Map } from "immutable";
import { streakerPropability } from "../../App";

const mazes = [
  [
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 1],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0]
  ],
  [
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 1],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0],
    [0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0]
  ],
  [
    [0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0],
    [1, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0],
    [0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0]
  ],
  [
    [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0]
  ],
  [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ],
  [
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0],
    [0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 1],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0],
    [1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    [0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0]
  ],
  [
    [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    [1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1],
    [0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ],
  [
    [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    [0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1],
    [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ],
  [
    [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0],
    [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0],
    [0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    [0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0],
    [0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1],
    [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0]
  ]
];
const mazeHeight = mazes[0].length;
const mazeWidth = mazes[0][0].length;
const mazeIdentifiers = [
  { xa: 0, ya: 1, xb: 5, yb: 2 },
  { xa: 1, ya: 3, xb: 4, yb: 1 },
  { xa: 3, ya: 3, xb: 5, yb: 3 },
  { xa: 0, ya: 0, xb: 0, yb: 3 },
  { xa: 3, ya: 5, xb: 4, yb: 2 },
  { xa: 2, ya: 4, xb: 4, yb: 0 },
  { xa: 1, ya: 0, xb: 1, yb: 5 },
  { xa: 3, ya: 0, xb: 2, yb: 3 },
  { xa: 0, ya: 4, xb: 2, yb: 1 }
];

class DribblingMiniGame extends PureComponent {
  constructor(props) {
    super(props);

    let startX = Math.floor(Math.random() * 6);
    let startY = Math.floor(Math.random() * 6);
    let targetX, targetY, dx, dy;
    do {
      targetX = Math.floor(Math.random() * 6);
      targetY = Math.floor(Math.random() * 6);
      dx = Math.abs(targetX - startX);
      dy = Math.abs(targetY - startY);
    } while (dx <= 1 && dy <= 1); // Can't be next to each other.

    let initialState = Map({
      mazeIndex: Math.floor(Math.random() * mazes.length),
      currentX: startX,
      currentY: startY,
      targetX: targetX,
      targetY: targetY
    });
    this.state = { data: initialState };

    this.handleUpClicked = this.handleUpClicked.bind(this);
    this.handleRightClicked = this.handleRightClicked.bind(this);
    this.handleDownClicked = this.handleDownClicked.bind(this);
    this.handleLeftClicked = this.handleLeftClicked.bind(this);
    this.performSteps = this.performSteps.bind(this);
    this.validateSteps = this.validateSteps.bind(this);
  }

  render() {
    let boardMargin = 13;
    let boardSize = 74;
    let squareSize = 5;
    let spacing = 6.5;
    let viewSize = 100;
    let buttonWidth = 50;
    let currentX = this.state.data.get("currentX");
    let currentY = this.state.data.get("currentY");
    let targetX = this.state.data.get("targetX");
    let targetY = this.state.data.get("targetY");
    let mazeIndex = this.state.data.get("mazeIndex");
    let mazeIds = mazeIdentifiers[mazeIndex];

    var markers = () => {
      let rows = [];
      for (let y = 0; y < 6; y++) {
        let row = [];
        for (let x = 0; x < 6; x++) {
          let fill = "#FFFFFF";
          if (x === currentX && y === currentY) {
            fill = "#000000";
          } else if (x === targetX && y === targetY) {
            fill = "#FF0000";
          }
          row.push(
            <rect
              key={y * 6 + x}
              stroke="#FFFFFF"
              strokeWidth="0.5"
              fill={fill}
              x={boardMargin + spacing + x * (spacing + squareSize)}
              y={boardMargin + spacing + y * (spacing + squareSize)}
              width={squareSize}
              height={squareSize}
            />
          );

          if (
            (x === mazeIds.xa && y === mazeIds.ya) ||
            (x === mazeIds.xb && y === mazeIds.yb)
          ) {
            row.push(
              <circle
                key={x + "circle" + y}
                cx={
                  boardMargin +
                  spacing +
                  x * (spacing + squareSize) +
                  squareSize / 2
                }
                cy={
                  boardMargin +
                  spacing +
                  y * (spacing + squareSize) +
                  squareSize / 2
                }
                fill="none"
                stroke="white"
                strokeWidth="0.25"
                r={squareSize}
              />
            );
          }
        }
        rows.push(row);
      }

      return rows;
    };
    return (
      <div className="DribblingMiniGame">
        <p className="game-name">Driblinger</p>
        <div className="container">
          <div className="game-area">
            <svg className="game-board" viewBox={`0 0 ${viewSize} ${viewSize}`}>
              <rect
                x={boardMargin}
                y={boardMargin}
                width={boardSize}
                height={boardSize}
                fill={"rgb(24, 163, 54)"}
              />
              {markers()}
              <path
                className="control-arrow"
                onClick={this.handleUpClicked}
                d={`M${viewSize / 2} 0 L ${(viewSize - buttonWidth) /
                  2} ${boardMargin - 1} H ${(viewSize + buttonWidth) / 2}`}
              />
              <path
                className="control-arrow"
                onClick={this.handleRightClicked}
                d={`M${viewSize} ${viewSize / 2} L ${viewSize -
                  boardMargin +
                  1} ${(viewSize - buttonWidth) / 2} V ${(viewSize +
                  buttonWidth) /
                  2}`}
              />
              <path
                className="control-arrow"
                onClick={this.handleDownClicked}
                d={`M${viewSize / 2} ${viewSize} L ${(viewSize - buttonWidth) /
                  2} ${viewSize - boardMargin + 1} H ${(viewSize +
                  buttonWidth) /
                  2}`}
              />
              <path
                className="control-arrow"
                onClick={this.handleLeftClicked}
                d={`M0 ${viewSize / 2} L ${boardMargin - 1} ${(viewSize -
                  buttonWidth) /
                  2} V ${(viewSize + buttonWidth) / 2}`}
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  handleUpClicked() {
    this.performSteps(0, -1, 0, -2);
  }

  handleRightClicked() {
    this.performSteps(1, 0, 2, 0);
  }

  handleDownClicked() {
    this.performSteps(0, 1, 0, 2);
  }

  handleLeftClicked() {
    this.performSteps(-1, 0, -2, 0);
  }

  performSteps(dx1, dy1, dx2, dy2) {
    let currentX = this.state.data.get("currentX");
    let currentY = this.state.data.get("currentY");
    let x1 = currentX * 2 + dx1;
    let y1 = currentY * 2 + dy1;
    let x2 = currentX * 2 + dx2;
    let y2 = currentY * 2 + dy2;

    if (!this.validateSteps(x1, y1, x2, y2)) {
      this.props.callback(false);
      return;
    }
    let targetX = this.state.data.get("targetX");
    let targetY = this.state.data.get("targetY");
    if (x2 / 2 === targetX && y2 / 2 === targetY) {
      this.props.callback(true);
    }

    this.setState(
      {
        data: this.state.data.set("currentX", x2 / 2).set("currentY", y2 / 2)
      },
      () => {
        if (Math.random() < streakerPropability) {
          this.props.streakerTrigger();
        }
      }
    );
  }

  validateSteps(x1, y1, x2, y2) {
    if (
      x1 < 0 ||
      y1 < 0 ||
      x2 < 0 ||
      y2 < 0 ||
      x1 >= mazeWidth ||
      y1 >= mazeHeight ||
      x2 >= mazeWidth ||
      y2 >= mazeHeight
    ) {
      return false;
    }

    let mazeIndex = this.state.data.get("mazeIndex");
    let maze = mazes[mazeIndex];

    if (maze[y1][x1] === 1 || maze[y2][x2] === 1) {
      return false;
    }
    return true;
  }
}

export default DribblingMiniGame;
