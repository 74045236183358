import React, { PureComponent } from "react";
import { Map, List, fromJS } from "immutable";
import { streakerPropability } from "../../App";

class EspionageMiniGame extends PureComponent {
  constructor(props) {
    super(props);
    const symbols = [
      ["\u2643", "\u2376", "\u2367", "\u2108", "\u2345", "\u2371", "\u2374"],
      ["\u2316", "\u2643", "\u2374", "\u2114", "\u2628", "\u2371", "\u263F"],
      ["\u2645", "\u262B", "\u2114", "\u2346", "\u2647", "\u2367", "\u2628"],
      ["\u2640", "\u2422", "\u2670", "\u2345", "\u2346", "\u263F", "\u26A9"],
      ["\u266D", "\u26A9", "\u2670", "\u2646", "\u2422", "\u2722", "\u2761"],
      ["\u2640", "\u2316", "\u2625", "\u274B", "\u266D", "\u2380", "\u2377"]
    ];

    let rowIndex = Math.floor(Math.random() * symbols.length);
    let row = symbols[rowIndex];
    let buttonSymbolsRaw = [];
    let takenIndexes = [];
    while (buttonSymbolsRaw.length < 4) {
      let index = Math.floor(Math.random() * row.length);
      if (takenIndexes.includes(index)) {
        continue;
      }
      takenIndexes.push(index);
      let object = {
        symbol: row[index],
        index: index
      };
      buttonSymbolsRaw.push(object);
    }

    let buttonSymbols = List(fromJS(buttonSymbolsRaw));

    let buttonSymbolsSortedByIndexCount = buttonSymbols.sortBy(o =>
      o.get("index")
    );

    // console.log("bssorted: ", buttonSymbolsSortedByIndexCount.toJS());

    let initialState = Map({
      buttonSymbols: buttonSymbols,
      buttonSymbolsSortedByIndexCount: buttonSymbolsSortedByIndexCount,
      selectionIndex: 0
    });
    this.state = { data: initialState };

    this.handleSymbolSelection = this.handleSymbolSelection.bind(this);
  }

  render() {
    let buttonSymbols = this.state.data.get("buttonSymbols");
    let button0Selected = this.state.data.get("button0Selected");
    let button1Selected = this.state.data.get("button1Selected");
    let button2Selected = this.state.data.get("button2Selected");
    let button3Selected = this.state.data.get("button3Selected");

    return (
      <div className="EspionageMiniGame">
        <p className="game-name">Spionage</p>
        <div className="container">
          <div className="row">
            <div className="col-4 offset-2 col-sm-2 offset-sm-4">
              <button
                className={`btn symbol-button${
                  button0Selected ? " btn-success" : " btn-primary"
                }`}
                onClick={this.handleSymbolSelection}
                value="0"
              >
                {buttonSymbols.get(0).get("symbol")}
              </button>
            </div>
            <div className="col-4 col-sm-2">
              <button
                className={`btn symbol-button${
                  button1Selected ? " btn-success" : " btn-primary"
                }`}
                onClick={this.handleSymbolSelection}
                value="1"
              >
                {buttonSymbols.get(1).get("symbol")}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-4 offset-2 col-sm-2 offset-sm-4">
              <button
                className={`btn symbol-button${
                  button2Selected ? " btn-success" : " btn-primary"
                }`}
                onClick={this.handleSymbolSelection}
                value="2"
              >
                {buttonSymbols.get(2).get("symbol")}
              </button>
            </div>
            <div className="col-4 col-sm-2">
              <button
                className={`btn symbol-button${
                  button3Selected ? " btn-success" : " btn-primary"
                }`}
                onClick={this.handleSymbolSelection}
                value="3"
              >
                {buttonSymbols.get(3).get("symbol")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSymbolSelection(e) {
    let buttonIndex = e.target.value;
    let buttonSymbols = this.state.data.get("buttonSymbols");
    let buttonSymbolsSortedByIndexCount = this.state.data.get(
      "buttonSymbolsSortedByIndexCount"
    );
    let pressedSymbol = buttonSymbols.get(buttonIndex);
    let selectionIndex = this.state.data.get("selectionIndex");
    let requiredSymbol = buttonSymbolsSortedByIndexCount.get(selectionIndex);
    let pressedSymbolIndex = pressedSymbol.get("index");
    let requiredSymbolIndex = requiredSymbol.get("index");

    if (pressedSymbolIndex === requiredSymbolIndex) {
      let nextSelectionIndex = selectionIndex + 1;
      let buttonSelectedKey =
        buttonIndex === "0"
          ? "button0Selected"
          : buttonIndex === "1"
            ? "button1Selected"
            : buttonIndex === "2"
              ? "button2Selected"
              : "button3Selected";

      this.setState(
        {
          data: this.state.data
            .set(buttonSelectedKey, true)
            .set("selectionIndex", nextSelectionIndex)
        },
        () => {
          if (nextSelectionIndex >= buttonSymbolsSortedByIndexCount.size) {
            this.props.callback(true);
          } else if (Math.random() < streakerPropability) {
            this.props.streakerTrigger();
          }
        }
      );
    } else {
      this.props.callback(false);
    }
  }
}

export default EspionageMiniGame;
