import React, { PureComponent } from "react";

class PassIndicatorSVG extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { highlighted: false };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    let strokeWidth = 1.5;
    let receiverIndex = this.props.pass.get("receiverIndex");
    let receiver = this.props.players.get(receiverIndex);
    let colorIndex = this.props.pass.get("colorIndex");
    let strokeColor = this.props.colors.get(colorIndex);
    let x1 = this.props.playerInPossession.get("x");
    let y1 = this.props.playerInPossession.get("y");
    let x2 = receiver.get("x");
    let y2 = receiver.get("y");
    return (
      <path
        d={`M${x1} ${y1} L ${x2} ${y2}`}
        stroke={this.state.highlighted ? "#FFFFFF" : strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      />
    );
  }

  handleMouseEnter() {
    this.setState({ highlighted: true });
  }

  handleMouseLeave() {
    this.setState({ highlighted: false });
  }

  handleClick() {
    this.props.callback(this.props.passIndex);
  }
}

export default PassIndicatorSVG;
